/** @jsx jsx */
import { jsx, Text, useColorMode } from "theme-ui";
import React from "react";
import { gridStyles, bodyColumn, readingColumn } from "../styles/shared.styles";
import { graphql } from "gatsby";
import PostList from "../components/post-list/Index";
import SEO from "../components/seo";

var { useEffect } = React;

const Reading = (props) => {
	var { allMdx } = props.data;
	console.log("MY MDX", allMdx);

	return (
		<>
			<SEO title="Reading" />
			<main sx={{ ...gridStyles, marginTop: 5 }} role="main">
				<div sx={readingColumn}>
					<Text variant="display" sx={{ marginBottom: 3 }} as="h1">
						Reading
					</Text>
					<Text variant="body" sx={{ color: "grays.60" }}>
						A working list of all of the books I'm reading in 2020.
						Ranging from algorithms to being a better manager, I try
						to dedicate my early mornings to exploring this variety
						of topics.
					</Text>
					<PostList data={allMdx} />
				</div>
			</main>
		</>
	);
};

export default Reading;

// gets access to all of my OSS projects
export const query = graphql`
	query {
		allMdx(filter: { fileAbsolutePath: { regex: "/reading/" } }) {
			edges {
				node {
					id
					frontmatter {
						title
						author
						description
						externalSlug
						coverImage {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					fileAbsolutePath
					fields {
						slug
					}
					body
				}
			}
		}
	}
`;
